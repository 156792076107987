<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>消息推送</div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">

                    <el-form-item label="推送时间设置" prop="F_TI_LEIX">
                        <el-select v-model="info.F_TI_LEIX" placeholder="选择性别">
                            <el-option label="全部" value=""></el-option>
                            <el-option label="每分钟" value="1"></el-option>
                            <el-option label="每五分钟" value="2"></el-option>
                            <el-option label="每十分钟" value="3"></el-option>
                            <el-option label="每十五分钟" value="4"></el-option>
                            <el-option label="每三十分钟" value="5"></el-option>
                            <el-option label="每小时" value="6"></el-option>
                            <el-option label="每天8点" value="7"></el-option>
                            <el-option label="每天13点" value="8"></el-option>
                            <el-option label="每天8点及13点" value="9"></el-option>
                            <el-option label="每周一8点" value="10"></el-option>
                            <el-option label="每月1日8点" value="11"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="推送标题" prop="F_VR_TUISBT"><el-input type="textarea" placeholder="请输入标题" maxlength="200" show-word-limit v-model="info.F_VR_TUISBT"></el-input></el-form-item>
                    <el-form-item label="推送内容" prop="F_VR_TUISNR"><el-input type="textarea" placeholder="请输入内容" maxlength="500" show-word-limit v-model="info.F_VR_TUISNR"></el-input></el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                info:{
                    F_TI_LEIX:''
                },
            };
        },
        watch: {},
        computed: {},
        methods: {
            submitForm:function(e){
                this.$refs[e].validate(res=>{
                    if(res){
                        // Http 请求
                        this.$post(this.$api.xxtsszConfig,this.info).then(postRes=>{
                            if(postRes.code == 200){
                                this.$message.success("编辑成功");
                                this.get_web_config();
                            }else{
                                this.$message.error("编辑失败");
                            }
                        });
                    }
                });
            },
            handleAvatarSuccess(res) {
                this.info.guanggt = res.data;
                this.$forceUpdate();
            },
            get_web_config(){
                this.$get(this.$api.xxtsszConfig).then(res=>{
                    console.log(666)
                    console.log(res.data)
                    console.log(666)
                    this.info  = res.data;
                });
            },

        },
        created() {
            this.get_web_config();
            this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到
        },
        mounted() {}
    };
</script>
<style lang="scss" scoped>

</style>